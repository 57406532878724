<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('external_research.circular_list') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="bg-primary" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + 1) }}
                    </template>
                    <template v-slot:cell(submission_deadline)="data">
                      {{ data.item.submission_deadline | dateFormat }}
                    </template>
                    <template v-slot:cell(org_id)="data">
                      {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.organizationProfileList, data.item.org_id) }}
                    </template>
                    <template v-slot:cell(thematic_name)="data">
                      {{ $i18n.locale === 'en' ? data.item.circular_name : data.item.circular_name_bn }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button class="mr-1" title="View" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                      <b-button title="Proposal Entry" size="sm" variant=" iq-bg-success border-success" v-if="data.item.circular_type === 1">
                        <router-link :to="'proposal-submission-form?circluar_id='+data.item.id+'&&t='+data.item.thematic_area_id">{{ $t('external_research.apply') }}</router-link>
                      </b-button>
                      <b-button title="Proposal Entry" size="sm" v-b-modal.modal-4 @click="entry(data.item)" variant="iq-bg-success border-success" v-if="data.item.circular_type === 2">
                        {{ $t('external_research.apply') }}
                      </b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-5" size="xl" :title="$t('research_manage.proposal_invitation') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details :item="item" :key="testId" ref="details"></Details>
    </b-modal>
    <b-modal no-close-on-backdrop id="modal-4" size="xl" :title="$t('research_manage.proposal_invitation') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <BiriForm v-if="item.org_id === 6" :item="item" :key="testId" ref="form"></BiriForm>
      <Form v-else :item="item" :key="testId" ref="form"></Form>
    </b-modal>
  </b-container>
</template>
<script>
  import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
  import { proposalPublishList } from '../../../api/routes'
  import Details from './Details'
  import Form from './Form'
  import BiriForm from './BiriForm'
  import RouteBasedList from '@/mixins/route-base-list'
  export default {
    mixins: [RouteBasedList],
    components: {
      Details,
      Form,
      BiriForm
    },
    data () {
      return {
        baseUrl: agriResearchServiceBaseUrl,
        testId: 0,
        rows: [],
        item: ''
      }
    },
    watch: {
      loadingState: function (newVal, oldVal) {
        if (newVal) {
          this.loadData()
        }
      }
    },
    computed: {
      thematicAreaList: function () {
        return this.$store.state.ExternalAgriResearch.agriResearchCommonObj.thematicAreaList.filter(item => item.status === 1)
      },
      formTitle () {
        return (this.editItemId === 0) ? this.$t('research_manage.proposal_invitation') + ' ' + this.$t('globalTrans.entry') : this.$t('research_manage.proposal_invitation') + ' ' + this.$t('globalTrans.update')
      },
      currentLocale () {
        return this.$i18n.locale
      },
      columns () {
        const labels = [
            { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('globalTrans.organization'), class: 'text-left' },
          { label: this.$t('research_manage.invitation_code'), class: 'text-left' },
          { label: this.$t('research_manage.circular_name'), class: 'text-left' },
          { label: this.$t('research_manage.submission_deadline'), class: 'text-left' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
          ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
          keys = [
           { key: 'index' },
          { key: 'org_id' },
          { key: 'memo_no' },
          { key: 'thematic_name' },
          { key: 'submission_deadline' },
          { key: 'action' }
          ]
        } else {
          keys = [
           { key: 'index' },
            { key: 'org_id' },
            { key: 'memo_no' },
            { key: 'thematic_name' },
            { key: 'submission_deadline' },
            { key: 'action' }
          ]
        }

        return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
      }
    },
    mounted () {
      this.loadData()
    },
    methods: {
      getColumnName (list, groupId) {
        const obj = list.find(item => item.value === groupId)
        if (typeof obj !== 'undefined') {
          if (this.$i18n.locale === 'bn') {
            return obj.text_bn
          }
          return obj.text_en
        } else {
            return ''
        }
      },
      details (item) {
        this.item = item
      },
      entry (item) {
        this.item = item
      },
      loadData () {
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(agriResearchServiceBaseUrl, proposalPublishList).then(response => {
            if (response.success) {
              this.$store.dispatch('setList', this.dataList(response.data.data))
              this.paginationData(response.data)
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
      },
      dataList (data) {
        const listData = data.map(item => {
          const thematicAreaObj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.thematicAreaList.find(Item => Item.value === parseInt(item.thematic_area_id))
            const thematicAreaData = {}
            if (typeof thematicAreaObj !== 'undefined') {
              thematicAreaData.thematic_name = thematicAreaObj.text_en
              thematicAreaData.thematic_name_bn = thematicAreaObj.text_bn
            } else {
              thematicAreaData.thematic_name = ''
              thematicAreaData.thematic_name_bn = ''
            }
          return Object.assign({}, item, thematicAreaData)
        })
        return listData
      }
    }
  }
</script>
