<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form id="form" @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" >
                  <b-row>
                    <b-col lg="12" sm="12">
                      <center><h4><b>{{ $t('research_manage.circular_name') }} :</b> {{ item.circular_name }} </h4></center>
                    </b-col>
                    <b-col lg="12" sm="12">
                      <center><h4><b>{{ $t('research_manage.memo_no') }} :</b> {{ item.memo_no }} </h4></center>
                    </b-col>
                    <!-- <b-col lg="3" sm="3">
                        <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                :disabled="true"
                                v-model="project_info.org_id"
                                :options="orgList"
                                id="org_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback d-block">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col> -->
                    <b-col lg="3" sm="12">
                      <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="office_type_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('org_pro.office_type')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                            plain
                            v-model="project_info.office_type_id"
                            :options="officeTypeList"
                            id="office_type_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="3" sm="12">
                      <ValidationProvider name="Office Name" vid="office_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="office_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('org_pro.office')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                            plain
                            v-model="project_info.office_id"
                            :options="officeList"
                            id="office_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="3" sm="12">
                      <ValidationProvider name="Thematic Area" rules="required|min_value:1" >
                        <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="thematic_area_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('research_manage.thematic_area_name')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="project_info.thematic_area_id"
                              :options="thematicAreaList"
                              id="thematic_area_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback d-block">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="3" sm="12">
                      <ValidationProvider name="Project Area" rules="required|min_value:1">
                        <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="project_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('research_manage.project_name')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="project_info.project_id"
                              :options="projectListDrop"
                              id="project_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback d-block">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="3" sm="12">
                      <ValidationProvider name="Sub Project Area" :rules="subProjectList.length ? 'required|min_value:1' : ''">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="sub_project_id"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('research_manage.sub_project_name')}} <span v-if="subProjectList.length" class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="project_info.sub_project_id"
                            :options="subProjectList"
                            id="sub_project_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                          <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="3" sm="12">
                      <ValidationProvider name="Research Type" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="research_type"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('research_manage.research_type')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="project_info.type"
                              :options="researchType"
                              id="research_type"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback d-block">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="3" sm="12" v-if="project_info.type">
                      <ValidationProvider name="Title (En)" vid="project_title_en" rules="required" ref="investigatorNameEnValidator">
                        <b-form-group
                          label-cols-sm="12"
                          label-for="project_title_en"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ getColumnName(researchType, project_info.type) }} ({{ $t('globalTrans.en') }}) <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="project_title_en"
                            v-model="project_info.project_title"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="3" sm="12" v-if="project_info.type">
                      <ValidationProvider name="Title (Bn)" vid="project_title_bn" rules="required">
                        <b-form-group
                          label-cols-sm="12"
                          label-for="project_title_bn"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ getColumnName(researchType, project_info.type) }} ({{ $t('globalTrans.bangla') }})  <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="project_title_bn"
                            v-model="project_info.project_title_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Objective (En)" vid="objective" rules="required">
                        <b-form-group
                          label-cols-sm="12"
                          label-for="objective"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                           {{ $t('research_manage.objectives')}} ({{ $t('globalTrans.en') }})  <span class="text-danger">*</span>
                          </template>
                          <vue-editor
                            id="objective"
                            v-model="project_info.objective"
                            :state="errors[0] ? false : (valid ? true : null)"
                          >
                          </vue-editor>
                          <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Objective (Bn)" vid="objective_bn">
                        <b-form-group
                          label-cols-sm="12"
                          label-for="objective_bn"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                           {{ $t('research_manage.objectives')}} ({{ $t('globalTrans.bn') }})
                          </template>
                          <vue-editor
                            id="objective_bn"
                            v-model="project_info.objective_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                          >
                          </vue-editor>
                          <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Introduction (En)" vid="introduction" rules="required">
                        <b-form-group
                          label-cols-sm="12"
                          label-for="introduction"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                           {{ $t('external_research.introduction')}} ({{ $t('globalTrans.en') }})  <span class="text-danger">*</span>
                          </template>
                          <vue-editor
                            id="introduction"
                            v-model="project_info.introduction"
                            :state="errors[0] ? false : (valid ? true : null)"
                          >
                          </vue-editor>
                          <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Introduction (Bn)" vid="introduction_bn">
                        <b-form-group
                          label-cols-sm="12"
                          label-for="introduction_bn"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                           {{ $t('external_research.introduction')}} ({{ $t('globalTrans.bn') }})
                          </template>
                          <vue-editor
                            id="introduction_bn"
                            v-model="project_info.introduction_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                          >
                          </vue-editor>
                          <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Relational (En)" vid="relational" rules="required">
                        <b-form-group
                            label-cols-sm="12"
                            label-for="relational"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('external_research.relational')}} ({{ $t('globalTrans.en') }})  <span class="text-danger">*</span>
                            </template>
                            <vue-editor
                            id="relational"
                            v-model="project_info.relational"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </vue-editor>
                            <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Relational (Bn)" vid="relational_bn">
                        <b-form-group
                            label-cols-sm="12"
                            label-for="relational_bn"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('external_research.relational')}} ({{ $t('globalTrans.bn') }})
                            </template>
                            <vue-editor
                            id="relational_bn"
                            v-model="project_info.relational_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </vue-editor>
                            <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Materials Methods (En)" vid="materials_methods" rules="required">
                            <b-form-group
                                label-cols-sm="12"
                                label-for="materials_methods"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('external_research.materials_methods')}} ({{ $t('globalTrans.en') }}) <span class="text-danger">*</span>
                                </template>
                                <vue-editor
                                id="materials_methods"
                                v-model="project_info.materials_methods"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </vue-editor>
                                <div class="invalid-feedback d-block">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Materials Methods (Bn)" vid="materials_methods_bn">
                            <b-form-group
                                label-cols-sm="12"
                                label-for="materials_methods_bn"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('external_research.materials_methods')}} ({{ $t('globalTrans.bn') }})
                                </template>
                                <vue-editor
                                id="materials_methods_bn"
                                v-model="project_info.materials_methods_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </vue-editor>
                                <div class="invalid-feedback d-block">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Expected Output (En)" vid="expected_output" rules="required">
                          <b-form-group
                                  label-cols-sm="12"
                                  label-for="expected_output"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{ $t('external_research.expected_output')}} ({{ $t('globalTrans.en') }})  <span class="text-danger">*</span>
                              </template>
                              <vue-editor
                                  id="expected_output"
                                  v-model="project_info.expected_output"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                              </vue-editor>
                              <div class="invalid-feedback d-block">
                                  {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Expected Output (Bn)" vid="expected_output_bn">
                            <b-form-group
                                    label-cols-sm="12"
                                    label-for="expected_output_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('external_research.expected_output')}} ({{ $t('globalTrans.bn') }})
                                </template>
                                <vue-editor
                                    id="expected_output_bn"
                                    v-model="project_info.expected_output_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </vue-editor>
                                <div class="invalid-feedback d-block">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Expected Output (En)" vid="expected_outcome" rules="required">
                          <b-form-group
                                  label-cols-sm="12"
                                  label-for="expected_outcome"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{ $t('external_research.expected_outcome')}} ({{ $t('globalTrans.en') }}) <span class="text-danger">*</span>
                              </template>
                              <vue-editor
                                  id="expected_outcome"
                                  v-model="project_info.expected_outcome"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                              </vue-editor>
                              <div class="invalid-feedback d-block">
                                  {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Expected Output (Bn)" vid="expected_outcome_bn">
                            <b-form-group
                                    label-cols-sm="12"
                                    label-for="expected_outcome_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('external_research.expected_outcome')}} ({{ $t('globalTrans.bn') }})
                                </template>
                                <vue-editor
                                    id="expected_outcome_bn"
                                    v-model="project_info.expected_outcome_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </vue-editor>
                                <div class="invalid-feedback d-block">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Conclusion (En)" vid="conclusion" rules="required">
                          <b-form-group
                                  label-cols-sm="12"
                                  label-for="conclusion"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{ $t('external_research.conclusion')}} ({{ $t('globalTrans.en') }}) <span class="text-danger">*</span>
                              </template>
                              <vue-editor
                                  id="conclusion"
                                  v-model="project_info.conclusion"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                              </vue-editor>
                              <div class="invalid-feedback d-block">
                                  {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Conclusion (Bn)" vid="conclusion_bn">
                            <b-form-group
                                    label-cols-sm="12"
                                    label-for="conclusion_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('external_research.conclusion')}} ({{ $t('globalTrans.bn') }})
                                </template>
                                <vue-editor
                                    id="conclusion_bn"
                                    v-model="project_info.conclusion_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </vue-editor>
                                <div class="invalid-feedback d-block">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="3" sm="12" >
                      <ValidationProvider name="State of Acivity" vid="status_of_activity">
                        <b-form-group
                          label-cols-sm="12"
                          label-for="status_of_activity"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{ $t('external_research.status_of_activity')}}
                          </template>
                          <b-form-input
                            id="status_of_activity"
                            v-model="project_info.status_of_activity"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="3" sm="12" >
                      <ValidationProvider name="Date of Initiation" vid="date_of_initiation">
                        <b-form-group
                          label-cols-sm="12"
                          label-for="date_of_initiation"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{ $t('external_research.date_of_initiation')}}
                          </template>
                          <b-form-input
                            id="date_of_initiation"
                            v-model="project_info.date_of_initiation"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="3" sm="12" >
                      <ValidationProvider name="Date of Completion" vid="date_of_completion">
                        <b-form-group
                          label-cols-sm="12"
                          label-for="date_of_completion"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{ $t('external_research.date_of_completion')}}
                          </template>
                          <b-form-input
                            id="date_of_completion"
                            v-model="project_info.date_of_completion"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="3" sm="12" >
                      <ValidationProvider name="SDG Linkage" vid="sdg_linkage">
                        <b-form-group
                          label-cols-sm="12"
                          label-for="sdg_linkage"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{ $t('external_research.sdg_linkage')}}
                          </template>
                          <b-form-input
                            id="sdg_linkage"
                            v-model="project_info.sdg_linkage"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="3" sm="12" >
                      <ValidationProvider name="PL" vid="pl">
                        <b-form-group
                          label-cols-sm="12"
                          label-for="pl"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{ $t('external_research.pl')}}
                          </template>
                          <b-form-input
                            id="pl"
                            v-model="project_info.pl"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="3" sm="12" >
                      <ValidationProvider name="CLS" vid="cls">
                        <b-form-group
                          label-cols-sm="12"
                          label-for="cls"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{ $t('external_research.cls')}}
                          </template>
                          <b-form-input
                            id="cls"
                            v-model="project_info.cls"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <fieldset class="p-2 w-100">
                    <legend class="px-2 w-50 shadow-sm">{{$t('external_research.source_of_budget')}}</legend>
                    <b-row class="p-3">
                      <b-col lg="12" sm="12">
                        <table class="table" style="width:100%" border="1">
                          <thead class="thead">
                            <tr>
                              <th class="text-left" style="width:30%">{{ $t('globalTrans.description') }}</th>
                              <th class="text-left" style="width:30%">{{ $t('globalTrans.amount') }}</th>
                              <th class="text-left" style="width:20%"></th>
                            </tr>
                          </thead>
                          <tr v-for="(project, index1) in project_info.source_of_budget" :key="index1">
                            <td>
                              <ValidationProvider :vid="'description' + index1" name="Description">
                                <div slot-scope="{ valid, errors }">
                                  <b-form-input
                                    v-model="project.description"
                                    :id="'date_details['+index1+'][description]'"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :placeholder="$t('globalTrans.description')"
                                  ></b-form-input>
                                  <div class="error invalid-feedback d-block" role="alert">
                                    {{ errors[0] }}
                                  </div>
                                </div>
                              </ValidationProvider>
                            </td>
                            <td>
                              <ValidationProvider :vid="'amount' + index1" name="Amoun">
                                <div slot-scope="{ valid, errors }">
                                  <b-form-input
                                    v-model="project.amount"
                                    :id="'date_details['+index1+'][amount]'"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="error invalid-feedback d-block" role="alert">
                                    {{ errors[0] }}
                                  </div>
                                </div>
                              </ValidationProvider>
                            </td>
                            <td>
                              <button v-if="index1 != 0" @click="removeIt(index)" class="btn btn-sm btn-danger float-right" type="button">
                                <i class="fas fa-window-close m-0"></i>
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="7">
                              <button
                                @click="addMore"
                                class="btn btn-sm btn-primary float-right"
                                type="button"
                              ><i class="fas fa-plus"></i> {{$t('globalTrans.add_more')}}</button>
                            </td>
                          </tr>
                        </table>
                      </b-col>
                    </b-row>
                  </fieldset>
                </b-form>
              </ValidationObserver>
              <br/>
              <b-row class="text-right">
                <b-col>
                  <b-button type="button" @click="saveUpdate()" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.draft') }}</b-button>
                  <b-button type="button" @click="finalSave()" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.finalSave') }}</b-button>
                  <b-button variant="danger" type="button" class="btn-sm" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import { core } from '../../../../../../config/pluginInit'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import RestApi, { agriResearchServiceBaseUrl, authServiceBaseUrl } from '../../../../../../config/api_config'
  import { brriProposalSubStoreUpdate, researchProposalDetailsApi, circularList, projectList, projectSubInfo } from '../../../api/routes'
  import researchTestingModal from '@/mixins/research-testing-modal'
  import { VueEditor } from 'vue2-editor'
  export default {
    name: 'FormLayout',
    props: ['id', 'item'],
    mixins: [researchTestingModal],
    components: {
      VueEditor,
      ValidationObserver,
      ValidationProvider
    },
    data () {
      return {
        unitLoad: false,
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        options: [
          { value: 1, text: 'Coordinate Research' },
          { value: 2, text: 'Plain Research' }
        ],
        editIndex: '',
        memo_no: '',
        submission_deadline: '',
        flag_status: 1,
        project_info: {
          org_id: 0,
          office_type_id: 0,
          office_id: 0,
          thematic_area_id: 0,
          project_id: 0,
          sub_project_id: 0,
          type: 1,
          research_type: 3,
          project_title: null,
          project_title_bn: null,
          objective: null,
          objective_bn: null,
          introduction: null,
          introduction_bn: null,
          relational: null,
          relational_bn: null,
          materials_methods: null,
          materials_methods_bn: null,
          expected_output: null,
          expected_output_bn: null,
          expected_outcome: null,
          expected_outcome_bn: null,
          conclusion: null,
          conclusion_bn: null,
          status_of_activity: null,
          date_of_initiation: null,
          date_of_completion: null,
          sdg_linkage: null,
          pl: null,
          cls: null,
          source_of_budget: [
            {
              description: null,
              amount: 0
            }
          ]
        },
        projectData: {},
        circularList: [],
        projectListDrop: [],
        subProjectList: [],
        officerList: [],
        officeTypeList: [],
        officeList: [],
        thematicAreaOffice: [],
        thematicAreaList: [],
        projectListDropArray: [],
        circularData: []
      }
    },
    watch: {
      'project_info.org_id': function (newVal, oldVal) {
        this.getOfficeTypeList()
      },
      'project_info.office_type_id': function (newVal, oldVal) {
        this.getOfficeList()
      },
      'project_info.thematic_area_id': function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.getProjectListAta(newValue)
        }
      },
      'project_info.project_id': function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.getSubProjectListInfo()
        }
      }
    },
    computed: {
      fiscalYearList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList
      },
      orgList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      researchType () {
        const seasonList = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Expertise' : 'দক্ষতা', text_en: 'Expertise', text_bn: 'দক্ষতা' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Activity' : 'কার্যকলাপ', text_en: 'Activity', text_bn: 'কার্যকলাপ' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Study' : 'অধ্যয়ন', text_en: 'Study', text_bn: 'অধ্যয়ন' }
        ]
        return seasonList
      },
      currentLocale () {
        return this.$i18n.locale
      },
      designationList: function () {
        const objectData = this.$store.state.commonObj.designationList.filter(el => el.status === 0 && el.org_id === this.project_info.org_id)
        return objectData.map(project => {
          return { value: project.value, text: this.$i18n.locale === 'en' ? project.text_en : project.text_bn }
        })
      },
      yearList: function () {
        return this.$store.state.commonObj.yearList
      },
      monthList: function () {
        return this.$store.state.commonObj.monthList.map(month => {
          return { value: month.value, text: this.$i18n.locale === 'en' ? month.text_en : month.text_bn }
        })
      }
    },
    created () {
      this.unitLoad = true
      this.getCircularInfo()
      this.project_info.circular_main_id = this.item.id
      if (this.id) {
         this.getProjectData()
      } else {
         this.project_info = Object.assign({}, this.project_info, {
        })
      }
    },
    mounted () {
      core.index()
    },
    methods: {
      removeIt (index) {
        this.project_info.source_of_budget.splice(index, 1)
      },
      addMore () {
        this.project_info.source_of_budget.push({
          description: null,
          amount: 0
        })
      },
      getColumnName (list, groupId) {
        const obj = list.find(item => item.value === groupId)
        if (typeof obj !== 'undefined') {
          if (this.$i18n.locale === 'bn') {
            return obj.text_bn
          }
          return obj.text_en
        } else {
            return ''
        }
      },
      getProjectListAta (thermArea = null) {
        const projectListDropArrays = this.projectListDropArray.filter(projectListDropArray => projectListDropArray.thematic_area_id === thermArea)
        this.projectListDrop = projectListDropArrays.map(item => {
          return Object.assign({ value: item.value, text: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en, text_en: item.text_en, text_bn: item.text_bn, thematic_area_id: item.thematic_area_id })
        })
      },
      onPhotoChange (event) {
        const input = event.target
        const format = ['pdf', 'doc', 'docx', 'xls', 'xlsx']
        if (input.files && input.files[0]) {
            const ext = input.files[0].name.slice((input.files[0].name.lastIndexOf('.') - 1 >>> 0) + 2)
            const isSupport = format.find(el => el === ext)
            if (typeof isSupport !== 'undefined') {
              const reader = new FileReader()
              reader.onload = (e) => {
                  this.project_info.file = e.target.result
              }
              reader.readAsDataURL(input.files[0])
            } else {
              this.$toast.error({
                  project_title: 'Invalid File!',
                  message: 'Only pdf, doc, docx, xls and xlsx files are allowed',
                  color: '#D6E09B'
              })
              this.project_info.attachment = []
              this.project_info.file = ''
            }
        } else {
            this.project_info.file = ''
        }
      },
      getOfficeTypeList () {
        this.officeTypeList = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.filter(item => item.status === 0 && item.org_id === this.project_info.org_id).map(obj => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
      },
      getOfficeList () {
          this.officeList = this.$store.state.ExternalUserIrrigation.commonObj.officeList.filter(item => item.status === 0 && item.office_type_id === this.project_info.office_type_id).map(obj => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn }
              } else {
                  return { value: obj.value, text: obj.text_en }
              }
          })
      },
      async getCircularInfo () {
        const params = {
          circular_id: parseInt(this.item.id)
        }
        await RestApi.getData(agriResearchServiceBaseUrl, projectList, params)
        .then(response => {
          if (response.success) {
              this.project_info.org_id = this.item.org_id
              this.thematicAreaOffice = response.thematicArray
              this.thematicAreaList = this.getThematicAreaList()
              this.projectListDropArray = response.data
          }
        })
      },
      async getSubProjectListInfo () {
        const params = {
          project_setup_detail_id: parseInt(this.project_info.project_id)
        }
        await RestApi.getData(agriResearchServiceBaseUrl, projectSubInfo, params)
        .then(response => {
          if (response.success) {
            this.subProjectList = response.data.map(item => {
              return Object.assign({ value: item.value, text: this.$i18n.locale === 'bn' ? item.text_bn : item.text_bn, text_en: item.text_en })
            })
          } else {
            this.subProjectList = []
          }
        })
      },
      getDesignation (id) {
        const Obj = this.$store.state.commonObj.designationList.find(item => item.value === parseInt(id))
        if (Obj) {
            if (this.$i18n.locale === 'en') {
                return Obj.text_en
            } else {
                return Obj.text_bn
            }
        } else {
                return ''
        }
      },
      getOfficer (id) {
          const Obj = this.officerList.find(item => item.value === parseInt(id))
          if (Obj) {
              if (this.$i18n.locale === 'en') {
                  return Obj.text_en
              } else {
                  return Obj.text_bn
              }
          } else {
                  return ''
          }
      },
      getObjectiveList (objectId) {
          if (objectId) {
              return this.objectiveData.filter(item => item.value === parseInt(objectId))
          }
      },
      getInvestigatorList (objectId) {
          if (objectId) {
              return this.objectiveData.filter(item => item.value === parseInt(objectId))
          }
      },
      cancelItem () {
          this.detailsFormData = {}
          this.editIndex = ''
          this.$refs.form1.reset()
      },
      editItem (item, index) {
          this.detailsFormData = Object.assign({}, item)
          this.editIndex = index
      },
      deleteItem (index) {
          this.project_info.co_investigators.splice(index, 1)
      },
      async addItem () {
        let result = []
        if (this.editIndex !== '') {
            const editList = [...this.project_info.co_investigators]
            editList.splice(parseInt(this.editIndex), 1)
            result = [...editList]
        } else {
            result = this.project_info.co_investigators
        }
        const projectName = this.detailsFormData.investigator_name_en
        const newData = result.find(item => item.investigator_name_en === projectName)
        if (typeof newData === 'undefined') {
              if (this.editIndex !== '') {
                  this.project_info.co_investigators[parseInt(this.editIndex)].investigator_name_en = this.detailsFormData.investigator_name_en
                  this.project_info.co_investigators[parseInt(this.editIndex)].investigator_name_bn = this.detailsFormData.investigator_name_bn
              } else {
                  this.project_info.co_investigators.push(JSON.parse(JSON.stringify(this.detailsFormData)))
              }
              this.detailsFormData = {
                investigator_name_en: '',
                investigator_name_bn: ''
              }
              this.editIndex = ''
          } else {
              this.$toast.error({
                  project_title: '!',
                  message: 'Item already added',
                  color: '#ee5253'
              })
          }
          // reset form
          this.$nextTick(() => {
              this.$refs.form1.reset()
          })
      },
      getThematicAreaList () {
        const thematicAreaId = []
        this.thematicAreaOffice.forEach((item, key) => {
          const itThere = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.thematicAreaList.find(items => items.value === parseInt(item))
          thematicAreaId.push(itThere)
        })
        return thematicAreaId
      },
      async getCircularList () {
          if (this.project_info.org_id) {
            const params = {
            org_id: parseInt(this.project_info.org_id)
          }
          await RestApi.getData(agriResearchServiceBaseUrl, circularList, params)
          .then(response => {
            if (response.success) {
              this.circularData = response.data
            }
          })
          const circularListxx = this.circularData.map(item => {
            return Object.assign({ value: item.id, text: this.$i18n.locale === 'bn' ? item.circular_name_bn : item.circular_name, text_en: item.circular_name, text_bn: item.circular_name_bn, memo_no: item.memo_no, submission_deadline: item.submission_deadline, org_id: item.org_id, circular_type: item.circular_type })
          })
          this.circularList = circularListxx.filter(circular => circular.circular_type === 2)
          if (this.id) {
            this.getCircularMemo(this.project_info.circular_id)
          }
        }
      },
      getCircularMemo (orgId) {
        if (this.project_info.circular_id) {
          const memo = this.circularList.find(circular => circular.value === orgId && circular.org_id === parseInt(this.project_info.org_id))
          this.memo_no = memo.memo_no
          this.submission_deadline = memo.submission_deadline
        }
      },
      getProjectData () {
        // const tmpData = this.$store.state.list.find(project => project.id === this.id)
        // return JSON.parse(JSON.stringify(tmpData))
        RestApi.getData(agriResearchServiceBaseUrl, researchProposalDetailsApi, { proposal_id: this.id }).then(response => {
            if (response.success) {
                this.project_info = response.data
                this.getOfficerList()
                this.loadingData = true
            }
            // this.loadingData = false
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
      },
      finalSave () {
        this.$swal({
          project_title: this.$t('globalTrans.save_msg'),
          showCancelButton: true,
          confirmButtonText: this.$t('globalTrans.yes'),
          cancelButtonText: this.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.project_info.flag = 1
            this.saveUpdate()
          }
        })
      },
      async saveUpdate () {
        const isFormValid = await this.$refs.form.validate()
        if (!isFormValid) {
          return null
        }
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }
        result = await RestApi.postData(agriResearchServiceBaseUrl, brriProposalSubStoreUpdate, this.project_info)
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)
        if (result.success) {
          this.$toast.success({
            project_title: 'Success',
            message: this.id ? 'Data updated successfully' : 'Data save successfully',
            color: '#D6E09B'
          })
          this.$bvModal.hide('modal-4')
          this.$router.push({ path: '/research-farmer/research-management/proposal-submission' })
        } else {
          this.$refs.form.setErrors(result.errors)
          this.$toast.error({
            project_title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
      },
      async getOfficerList () {
        if (this.projectData.org_id > 0) {
          this.officerLoad = true
            const params = { org_id: parseInt(this.projectData.org_id) }
            const response = await RestApi.getData(authServiceBaseUrl, 'user/list-by-designation', params)
            if (response.success) {
                this.unitLoad = false
                this.officerLoad = false
                this.allOfficerList = response.data
                this.officerList = this.allOfficerList.map(item => ({
                    value: item.user_id,
                    text: this.$i18n.locale === 'bn' ? item.name_bn : item.name,
                    text_en: item.name,
                    text_bn: item.name_bn,
                    designation_id: item.designation_id
                }))
            } else {
                this.unitLoad = false
                this.officerLoad = false
                this.officerList = []
            }
        }
      }
    }
  }
</script>
