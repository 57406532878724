<template>
    <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template v-slot:body>
              <b-overlay :show="loadingState">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form id="form" @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" >
                    <b-row class="p-4">
                      <b-col style="background-color:green;" lg="6" class="offset-lg-3 p-2">
                        <template>
                          <h6 style="" class="text-sm-center text-light">{{ $t('external_research.circular_info') }}</h6>
                        </template>
                      </b-col>
                    </b-row>
                      <b-overlay :show="unitLoad">
                      <b-row>
                          <b-col lg="12" sm="12">
                            <center><h3><b>{{ $t('research_manage.circular_name') }} :</b> {{ item.circular_name }} </h3></center>
                          </b-col>
                          <b-col lg="12" sm="12">
                            <center><h3><b>{{ $t('research_manage.memo_no') }} :</b> {{ item.memo_no }} </h3></center>
                          </b-col>
                          <b-col lg="3" sm="12">
                              <ValidationProvider name="Thematic Area" rules="required|min_value:1" >
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="12"
                                      label-for="thematic_area_id"
                                      slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                      {{ $t('research_manage.thematic_area_name')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                      plain
                                      :disabled="true"
                                      v-model="project_info.thematic_area_id"
                                      :options="thematicAreaList"
                                      id="thematic_area_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col lg="3" sm="12">
                              <ValidationProvider name="Thematic Area" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="12"
                                      label-for="project_id"
                                      slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                      {{ $t('research_manage.project_name')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                      plain
                                      v-model="project_info.project_id"
                                      :options="projectList"
                                      id="project_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col lg="3" sm="12">
                              <ValidationProvider name="Thematic Area" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="12"
                                      label-for="objective_id"
                                      slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                      {{ $t('research_manage.objectives')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                      :disabled="true"
                                      plain
                                      v-model="project_info.objective_id"
                                      :options="objectiveList"
                                      id="objective_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col lg="3" sm="12">
                              <ValidationProvider name="Officer" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="12"
                                      label-for="pi_id"
                                      slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                      {{ $t('external_research.principal_investigator')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                      plain
                                      :disabled="true"
                                      v-model="project_info.pi_id"
                                      :options="officerList"
                                      id="pi_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                      </b-row>
                      </b-overlay>
                    <b-row class="p-4">
                      <b-col style="background-color:green;" lg="6" class="offset-lg-3 p-2">
                        <template>
                          <h6 style="" class="text-sm-center text-light">{{ $t('external_research.experiment_info') }}</h6>
                        </template>
                      </b-col>
                    </b-row>
                      <b-row>
                        <b-col lg="12" sm="12">
                          <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="org_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                      {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  :disabled="true"
                                  v-model="project_info.org_id"
                                  :options="orgList"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                          <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="office_type_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                      {{$t('org_pro.office_type')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="project_info.office_type_id"
                                  :options="officeTypeList"
                                  id="office_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                          <ValidationProvider name="Office Name" vid="office_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="office_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                      {{$t('org_pro.office')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="project_info.office_id"
                                  :options="officeList"
                                  id="office_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Experiment Name (En)" vid="project_name_en" rules="required" ref="investigatorNameEnValidator">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="experiment"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('external_research.experiment') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="experiment"
                              v-model="project_info.experiment"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Experiment Name (Bn)" vid="project_name_bn">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="experiment_bn"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('external_research.experiment') }} {{ $t('globalTrans.bn') }}
                            </template>
                            <b-form-input
                              id="experiment_bn"
                              v-model="project_info.experiment_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Objectives (En)" vid="objective_en" rules="required">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="objective_en"
                            slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{ $t('research_manage.objectives') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                            </template>
                            <b-textarea
                              id="objective_en"
                              v-model="project_info.objective"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Objectives (Bn)" vid="objective_bn">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="objective_bn"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('research_manage.objectives') }} {{ $t('globalTrans.bn') }}
                            </template>
                            <b-textarea
                              id="objective_bn"
                              v-model="project_info.objective_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Status (En)" vid="status_en" rules="required">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="status_en"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('globalTrans.status') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="status_en"
                              v-model="project_info.status_en"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Status (Bn)" vid="status_bn">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="status_bn"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('globalTrans.status') }} {{ $t('globalTrans.bn') }}
                            </template>
                            <b-form-input
                              id="status_bn"
                              v-model="project_info.status_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Rationale (En)" vid="rationale" rules="required">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="rationale"
                            slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{ $t('external_research.rationale') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                            </template>
                            <b-textarea
                              id="rationale"
                              v-model="project_info.rationale"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Relationale (Bn)" vid="rationale_bn">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="rationale_bn"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('external_research.rationale') }} {{ $t('globalTrans.bn') }}
                            </template>
                            <b-textarea
                              id="rationale_bn"
                              v-model="project_info.rationale_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="12" sm="12">
                        <b>{{ $t('external_research.materials_methods') }} :</b>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Exp. Materials (En)" vid="exp_materials" rules="required">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="exp_materials"
                            slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{ $t('external_research.exp_materials') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                            </template>
                            <b-textarea
                              id="exp_materials"
                              v-model="project_info.exp_materials"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Exp. Materials (Bn)" vid="exp_materials_bn">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="exp_materials_bn"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('external_research.exp_materials') }} {{ $t('globalTrans.bn') }}
                            </template>
                            <b-textarea
                              id="exp_materials_bn"
                              v-model="project_info.exp_materials_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Design (En)" vid="design" rules="required">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="design"
                            slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{ $t('external_research.design') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                            </template>
                            <b-textarea
                              id="design"
                              v-model="project_info.design"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Design (Bn)" vid="design_bn">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="design_bn"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('external_research.design') }} {{ $t('globalTrans.bn') }}
                            </template>
                            <b-textarea
                              id="design_bn"
                              v-model="project_info.design_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Treatment (En)" vid="treatment" rules="required">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="treatment"
                            slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{ $t('external_research.treatment') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                            </template>
                            <b-textarea
                              id="treatment"
                              v-model="project_info.treatment"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Treatment (Bn)" vid="treatment_bn">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="treatment_bn"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('external_research.treatment') }} {{ $t('globalTrans.bn') }}
                            </template>
                            <b-textarea
                              id="treatment_bn"
                              v-model="project_info.treatment_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Location (En)" vid="location" rules="required">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="location"
                            slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{ $t('external_research.location') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                            </template>
                            <b-textarea
                              id="location"
                              v-model="project_info.location"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Location (Bn)" vid="location_bn">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="location_bn"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('external_research.location') }} {{ $t('globalTrans.bn') }}
                            </template>
                            <b-textarea
                              id="location_bn"
                              v-model="project_info.location_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Plot Size (En)" vid="plot_size" rules="required">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="plot_size"
                            slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{ $t('external_research.plot_size') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                            </template>
                            <b-textarea
                              id="plot_size"
                              v-model="project_info.plot_size"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Plot Size (Bn)" vid="plot_size_bn">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="plot_size_bn"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('external_research.plot_size') }} {{ $t('globalTrans.bn') }}
                            </template>
                            <b-textarea
                              id="plot_size_bn"
                              v-model="project_info.plot_size_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Plantin with/or Spacing (En)" vid="planting_spacing" rules="required">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="planting_spacing"
                            slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{ $t('external_research.planting_spacing') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                            </template>
                            <b-textarea
                              id="planting_spacing"
                              v-model="project_info.planting_spacing"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Plantin with/or Spacing (Bn)" vid="planting_spacing_bn">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="planting_spacing_bn"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('external_research.planting_spacing') }} {{ $t('globalTrans.bn') }}
                            </template>
                            <b-textarea
                              id="planting_spacing_bn"
                              v-model="project_info.planting_spacing_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Fertilizer Dose & Method Application (En)" vid="fertilizer_dose_method" rules="required">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="fertilizer_dose_method"
                            slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{ $t('external_research.fertilizer_dose_method_application') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                            </template>
                            <b-textarea
                              id="fertilizer_dose_method"
                              v-model="project_info.fertilizer_dose_method"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Fertilizer Dose & Method Application (Bn)" vid="fertilizer_dose_method_bn">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="fertilizer_dose_method_bn"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('external_research.fertilizer_dose_method_application') }} {{ $t('globalTrans.bn') }}
                            </template>
                            <b-textarea
                              id="fertilizer_dose_method_bn"
                              v-model="project_info.fertilizer_dose_method_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Irrigated/Rainfed (En)" vid="irrigated_rainfed" rules="required">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="irrigated_rainfed"
                            slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{ $t('external_research.irrigated_rainfed') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                            </template>
                            <b-textarea
                              id="irrigated_rainfed"
                              v-model="project_info.irrigated_rainfed"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Irrigated/Rainfed (Bn)" vid="irrigated_rainfed_bn">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="irrigated_rainfed_bn"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('external_research.irrigated_rainfed') }} {{ $t('globalTrans.bn') }}
                            </template>
                            <b-textarea
                              id="irrigated_rainfed_bn"
                              v-model="project_info.irrigated_rainfed_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Data To Be Recorded (En)" vid="data_recorded" rules="required">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="data_recorded"
                            slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{ $t('external_research.data_to_be_recorded') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                            </template>
                            <b-textarea
                              id="data_recorded"
                              v-model="project_info.data_recorded"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Data To Be Recorded (Bn)" vid="data_recorded_bn">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="data_recorded_bn"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('external_research.data_to_be_recorded') }} {{ $t('globalTrans.bn') }}
                            </template>
                            <b-textarea
                              id="data_recorded_bn"
                              v-model="project_info.data_recorded_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                          <ValidationProvider name="Season">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="season"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('external_research.season')}}
                                  </template>
                                  <v-select
                                    id="season"
                                    v-model="project_info.season"
                                    multiple
                                    :reduce="op => op.value"
                                    :options="seasonList"
                                    label="text"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  </v-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="12" sm="12">
                        <b>{{ $t('external_research.duration') }} :</b>
                      </b-col>
                      <b-col>
                              <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules= "required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="fiscal_year_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('globalTrans.fiscalYear')}}<span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                    plain
                                    v-model="project_info.fiscal_year_id"
                                    :options="fiscalYearList"
                                    id="fiscal_year_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                      <b-col lg="3" sm="12">
                              <ValidationProvider name="Month From" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="12"
                                      label-for="month_from"
                                      slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                      {{ $t('globalTrans.month')}} {{ $t('globalTrans.from')}}<span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                      plain
                                      v-model="project_info.month_from"
                                      :options="monthList"
                                      id="month_from"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                      <b-col lg="3" sm="12">
                              <ValidationProvider name="Year From" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="12"
                                      label-for="year_from"
                                      slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                      {{ $t('globalTrans.year')}} {{ $t('globalTrans.from')}}<span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                      plain
                                      v-model="project_info.year_from"
                                      :options="yearList"
                                      id="year_from"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                      <b-col lg="3" sm="12">
                              <ValidationProvider name="Month To" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="12"
                                      label-for="month_to"
                                      slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                      {{ $t('globalTrans.month')}} {{ $t('globalTrans.to')}}<span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                      plain
                                      v-model="project_info.month_to"
                                      :options="monthList"
                                      id="month_to"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                      <b-col lg="3" sm="12">
                              <ValidationProvider name="Year To" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="12"
                                      label-for="year_to"
                                      slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                      {{ $t('globalTrans.year')}} {{ $t('globalTrans.to')}}<span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                      plain
                                      v-model="project_info.year_to"
                                      :options="yearList"
                                      id="year_to"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Expected Output/Benifit (En)" vid="expected_output" rules="required">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="expected_output"
                            slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{ $t('external_research.expected_output_benifit') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                            </template>
                            <b-textarea
                              id="expected_output"
                              v-model="project_info.expected_output"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Expected Output/Benifit (Bn)" vid="expected_output_bn">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="expected_output_bn"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('external_research.expected_output_benifit') }} {{ $t('globalTrans.bn') }}
                            </template>
                            <b-textarea
                              id="expected_output_bn"
                              v-model="project_info.expected_output_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                              <ValidationProvider name="Estimated Cost" rules="required">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="12"
                                      label-for="estimated_cost"
                                      slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                      {{ $t('external_research.estimated_cost')}} <span class="text-danger">*</span>
                                      </template>
                                    <b-form-input class="form-control"
                                        v-model="project_info.estimated_cost"
                                        id="estimated_cost"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Suorce of Fund (En)" vid="source_fund" rules="required">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="source_fund"
                            slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{ $t('external_research.source_fund') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                            </template>
                            <b-textarea
                              id="source_fund"
                              v-model="project_info.source_fund"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Suorce of Fund (Bn)" vid="source_fund_bn">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="source_fund_bn"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('external_research.source_fund') }} {{ $t('globalTrans.bn') }}
                            </template>
                            <b-textarea
                              id="source_fund_bn"
                              v-model="project_info.source_fund_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Priority (En)" vid="source_fund" rules="required">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="priority"
                            slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{ $t('external_research.priority') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                            </template>
                            <b-textarea
                              id="priority"
                              v-model="project_info.priority"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="Priority (Bn)" vid="priority_bn">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="priority_bn"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('external_research.priority') }} {{ $t('globalTrans.bn') }}
                            </template>
                            <b-textarea
                              id="priority_bn"
                              v-model="project_info.priority_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="6">
                          <ValidationProvider name="Completion Report" vid="completion_report">
                          <b-form-group class="row" label-cols-sm="12" label-for="completion_report" slot-scope="{ valid, errors }">
                              <template v-slot:label>
                              {{ $t('globalTrans.attachment') }}
                              <v-slot v-if="project_info.attachment !== null">
                                  <a :href="agriResearchServiceBaseUrl + 'download-attachment?file=storage/report-submission/' + project_info.attachment" title="Attachment" class="mr-3"><i class="ri-download-cloud-fill">{{project_info.attachment}}</i></a>
                              </v-slot>
                              </template>
                              <b-form-file
                              id="completion_report"
                              @change="onPhotoChange"
                              v-model="project_info.attachment"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              </b-form-file>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      </b-row>
                    </b-form>
                </ValidationObserver>
                <ValidationObserver ref="form1" v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(addItem)" @reset.prevent="reset" autocomplete="off">
                    <b-row class="p-4">
                      <b-col style="background-color:green;" lg="6" class="offset-lg-3 p-2">
                        <template>
                          <h6 style="" class="text-sm-center text-light">{{ $t('external_research.investigator_info') }}</h6>
                        </template>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <ValidationProvider name="Investigator Name (En)" vid="investigator_name_en" rules="required">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="investigator_name_en"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('external_research.principal_co_investigator_name_en') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="investigator_name_en"
                              v-model="detailsFormData.investigator_name_en"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="5" sm="12">
                        <ValidationProvider name="Investigator Name (Bn)" vid="investigator_name_bn" rules="required">
                          <b-form-group
                            label-cols-sm="12"
                            label-for="investigator_name_bn"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('external_research.principal_co_investigator_name_en') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="investigator_name_bn"
                              v-model="detailsFormData.investigator_name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col>
                          <div class="text-right mb-2">
                              <b-button type="button" v-if="editIndex !== ''" variant="danger" class="mr-1 btn-sm" @click="cancelItem()">{{ $t('globalTrans.cancel') }}</b-button>
                              <b-button type="submit" class="btn-sm" variant="success">{{ editIndex !== '' ? $t('globalTrans.update') : $t('globalTrans.add')}}</b-button>
                          </div>
                      </b-col>
                    </b-row>
                  </b-form>
                </ValidationObserver>
                <br/>
              <!-- List  -->
              <b-table-simple bordered>
                  <thead>
                    <tr style="background-color:green;">
                      <th style="width:5%" class="text-light">{{ $t('globalTrans.sl_no') }}</th>
                      <th style="width:10%" class="text-light">{{ $t('external_research.principal_co_investigator_name') }}</th>
                      <th style="width:10%" class="text-light">{{ $t('globalTrans.action') }}</th>
                    </tr>
                  </thead>
                  <b-tbody>
                      <b-tr v-for="(item, index) in project_info.co_investigators" :key="index">
                          <b-td>
                              {{ $n(index + 1) }}
                          </b-td>
                          <b-td>
                            {{ currentLocale == 'bn' ? item.investigator_name_bn : item.investigator_name_en }}
                          </b-td>
                          <b-td>
                            <span class="d-flex text-center">
                              <b-button @click="editItem(item, index)" title="Location Edit" class="btn btn-success btn-sm mr-1"><i class="ri-ball-pen-fill m-0"></i></b-button>
                              <b-button type="button" @click="deleteItem(index)" class="btn btn- iq-bg-danger btn-sm ml-1" variant="danger"><i class="ri-delete-bin-line m-0"></i></b-button>
                            </span>
                          </b-td>
                      </b-tr>
                      <!-- <b-tr v-if="project_info.co_investigators.length <= 0">
                          <td colspan="12" class="text-center">
                              <span class="text-black">{{ (currentLocale === 'bn') ? 'কোন তথ্য পাওয়া যায়নি!' : 'No Data Found' }}</span>
                          </td>
                      </b-tr> -->
                  </b-tbody>
                </b-table-simple>
              <b-overlay :show="unitLoad">
              <b-row class="text-right">
                <b-col>
                  <!-- <b-button type="button" @click="saveUpdate" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button> -->
                  <b-button type="button" @click="saveUpdate()" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.draft') }}</b-button>
                  <b-button type="button" @click="finalSave()" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.finalSave') }}</b-button>
                  <b-button variant="danger" type="button" class="btn-sm" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                </b-col>
              </b-row>
              </b-overlay>
                  </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
  </template>
  <script>
  import { core } from '../../../../../../config/pluginInit'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import RestApi, { agriResearchServiceBaseUrl, authServiceBaseUrl } from '../../../../../../config/api_config'
  import { divisionalProposalSubStoreUpdate, researchProposalDetailsApi, circularList, projectInfo } from '../../../api/routes'
  import researchTestingModal from '@/mixins/research-testing-modal'
  export default {
    name: 'FormLayout',
    props: ['id', 'item'],
    mixins: [researchTestingModal],
    components: {
      ValidationObserver,
      ValidationProvider
    },
    data () {
      return {
          unitLoad: false,
          saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
          options: [
            { value: 1, text: 'Coordinate Research' },
            { value: 2, text: 'Plain Research' }
          ],
          editIndex: '',
          memo_no: '',
          submission_deadline: '',
          flag_status: 1,
          project_info: {
              fiscal_year_id: 0,
              flag: 0,
              org_id: 0,
              circular_main_id: 0,
              proposal_auto_id: 0,
              office_type_id: 0,
              office_id: 0,
              divisional_id: 0,
              research_type: 3,
              thematic_area_id: 0,
              project_id: 0,
              objective_id: 0,
              pi_id: 0,
              estimated_cost: 0,
              priority: '',
              priority_bn: '',
              source_fund: '',
              source_fund_bn: '',
              expected_output: '',
              expected_output_bn: '',
              to_date: '',
              from_date: '',
              month_from: 0,
              month_to: 0,
              year_from: 0,
              year_to: 0,
              season: [],
              data_recorded: '',
              data_recorded_bn: '',
              irrigated_rainfed: '',
              irrigated_rainfed_bn: '',
              fertilizer_dose_method: '',
              fertilizer_dose_method_bn: '',
              planting_spacing: '',
              planting_spacing_bn: '',
              plot_size: '',
              plot_size_bn: '',
              location: '',
              location_bn: '',
              treatment: '',
              treatment_bn: '',
              design: '',
              design_bn: '',
              exp_materials: '',
              exp_materials_bn: '',
              materials_mathods: '',
              materials_mathods_bn: '',
              rationale: '',
              rationale_bn: '',
              status_en: '',
              status_bn: '',
              objective: '',
              objective_bn: '',
              experiment: '',
              experiment_bn: '',
              co_investigators: [
              ]
          },
          detailsFormData: {
              investigator_name_en: '',
              investigator_name_bn: ''
          },
          projectData: {},
          circularList: [],
          projectList: [],
          objectiveList: [],
          objectiveData: [],
          officerList: [],
          officeList: [],
          officeTypeList: [],
          divisionalResearchList: [],
          allOfficerList: [],
          investigatorList: [],
          attachment: [],
          file: '',
          circularData: []
      }
    },
    watch: {
      'project_info.org_id': function (newVal, oldVal) {
          this.getDivisionalOrgList()
          this.getOfficeTypeList()
      },
      'project_info.office_type_id': function (newVal, oldVal) {
          this.getOfficeList()
      },
      'project_info.project_id': function (newValue, oldValue) {
          // this.getCircularInfo()
          if (newValue !== oldValue) {
              this.objectiveList = this.getObjectiveList(newValue)
              this.project_info.objective_id = this.project_info.project_id
              const project = this.projectList.find(item => item.value === this.project_info.project_id)
              this.project_info.pi_id = project.investigator_id
          } else {
              this.objectiveList = []
          }
      }
    },
    computed: {
        fiscalYearList: function () {
            return this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList
        },
        orgList: function () {
            return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0)
        },
        seasonList () {
            const seasonList = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Aus' : 'আউশ', text_en: 'Aus', text_bn: 'আউশ' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Amon' : 'আমন', text_en: 'Amon', text_bn: 'আমন' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'Boro' : 'বোরো', text_en: 'Boro', text_bn: 'বোরো' },
                { value: 4, text: this.$i18n.locale === 'en' ? 'Kharif-1' : 'খরিফ-১', text_en: 'Kharif-1', text_bn: 'খরিফ-১' },
                { value: 5, text: this.$i18n.locale === 'en' ? 'Kharif-2' : 'খরিফ-২', text_en: 'Kharif-2', text_bn: 'খরিফ-২' }
            ]
            return seasonList
        },
      currentLocale () {
        return this.$i18n.locale
      },
      // orgList: function () {
      //   const objectData = this.$store.state.commonObj.organizationProfileList.filter(el => el.status === 0)
      //   return objectData.map(project => {
      //         return { value: project.value, text: this.$i18n.locale === 'en' ? project.text_en : project.text_bn }
      //       })
      // },
      designationList: function () {
        const objectData = this.$store.state.commonObj.designationList.filter(el => el.status === 0 && el.org_id === this.project_info.org_id)
        return objectData.map(project => {
              return { value: project.value, text: this.$i18n.locale === 'en' ? project.text_en : project.text_bn }
            })
      },
      thematicAreaList: function () {
        const objectData = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.thematicAreaList
        return objectData.map(project => {
              return { value: project.value, text: this.$i18n.locale === 'en' ? project.text_en : project.text_bn }
            })
      },
      yearList: function () {
        return this.$store.state.commonObj.yearList
      },
      monthList: function () {
        return this.$store.state.commonObj.monthList.map(month => {
              return { value: month.value, text: this.$i18n.locale === 'en' ? month.text_en : month.text_bn }
            })
      }
    },
    created () {
      this.unitLoad = true
      this.getCircularInfo()
      this.project_info.circular_main_id = this.item.id
      if (this.id) {
         this.getProjectData()
      } else {
         this.project_info = Object.assign({}, this.project_info, {
          // org_id: this.$store.state.dataFilters.orgId,
        })
        // if (this.$store.state.Auth.activeRoleId !== 1) {
        //   this.project_info.org_id = this.$store.state.Auth.authUser.org_id
        // }
      }
      if (this.$store.state.Auth.activeRoleId !== 1) {
        // this.project_info.org_id = this.$store.state.Auth.authUser.org_id
        this.project_info.office_type_id = this.$store.state.Auth.authUser.office_type_id
        this.project_info.office_id = this.$store.state.Auth.authUser.office_id
      }
    },
    mounted () {
      core.index()
    },
    methods: {
        onPhotoChange (event) {
          const input = event.target
          const format = ['pdf', 'doc', 'docx', 'xls', 'xlsx']
          if (input.files && input.files[0]) {
              const ext = input.files[0].name.slice((input.files[0].name.lastIndexOf('.') - 1 >>> 0) + 2)
              const isSupport = format.find(el => el === ext)
              if (typeof isSupport !== 'undefined') {
                const reader = new FileReader()
                reader.onload = (e) => {
                    this.project_info.file = e.target.result
                }
                reader.readAsDataURL(input.files[0])
              } else {
                this.$toast.error({
                    title: 'Invalid File!',
                    message: 'Only pdf, doc, docx, xls and xlsx files are allowed',
                    color: '#D6E09B'
                })
                this.project_info.attachment = []
                this.project_info.file = ''
              }
          } else {
              this.project_info.file = ''
          }
        },
        getDivisionalOrgList () {
            this.divisionalResearchList = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.divisionalResearchList.filter(item => item.status === 1 && item.org_id === parseInt(this.project_info.org_id)).map(obj => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        },
        getOfficeTypeList () {
            this.officeTypeList = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.filter(item => item.status === 0 && item.org_id === this.project_info.org_id).map(obj => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        },
        getOfficeList () {
            this.officeList = this.$store.state.ExternalUserIrrigation.commonObj.officeList.filter(item => item.status === 0 && item.office_type_id === this.project_info.office_type_id).map(obj => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        },
    async getCircularInfo () {
        const params = {
        circular_id: parseInt(this.item.id)
        }
        await RestApi.getData(agriResearchServiceBaseUrl, projectInfo, params)
        .then(response => {
            if (response.success) {
                this.projectData = response.data
                this.project_info.thematic_area_id = this.projectData.thematic_area_id
                this.project_info.org_id = this.projectData.org_id
                this.getOfficerList()
                this.projectList = this.projectData.project_info_details.map(item => {
                    return Object.assign({ value: item.id, text: this.$i18n.locale === 'bn' ? item.project_name_bn : item.project_name_en, text_en: item.project_name_en, text_bn: item.project_name_bn, investigator_id: item.pi_officer_id })
                })
                this.objectiveData = this.projectData.project_info_details.map(item => {
                    return Object.assign({ value: item.id, text: this.$i18n.locale === 'bn' ? item.objective_bn : item.objective_en, text_en: item.objective_en, text_bn: item.objective_bn })
                })
            }
        })
    },
    getDesignation (id) {
        const Obj = this.$store.state.commonObj.designationList.find(item => item.value === parseInt(id))
        if (Obj) {
            if (this.$i18n.locale === 'en') {
                return Obj.text_en
            } else {
                return Obj.text_bn
            }
        } else {
                return ''
        }
    },
    getOfficer (id) {
        const Obj = this.officerList.find(item => item.value === parseInt(id))
        if (Obj) {
            if (this.$i18n.locale === 'en') {
                return Obj.text_en
            } else {
                return Obj.text_bn
            }
        } else {
                return ''
        }
    },
    getObjectiveList (objectId) {
        if (objectId) {
            return this.objectiveData.filter(item => item.value === parseInt(objectId))
        }
     },
     getInvestigatorList (objectId) {
        if (objectId) {
            return this.objectiveData.filter(item => item.value === parseInt(objectId))
        }
     },
      cancelItem () {
          this.detailsFormData = {}
          this.editIndex = ''
          this.$refs.form1.reset()
      },
      editItem (item, index) {
          this.detailsFormData = Object.assign({}, item)
          this.editIndex = index
      },
      deleteItem (index) {
          this.project_info.co_investigators.splice(index, 1)
      },
      async addItem () {
        let result = []
        if (this.editIndex !== '') {
            const editList = [...this.project_info.co_investigators]
            editList.splice(parseInt(this.editIndex), 1)
            result = [...editList]
        } else {
            result = this.project_info.co_investigators
        }
        const projectName = this.detailsFormData.investigator_name_en
        const newData = result.find(item => item.investigator_name_en === projectName)
        if (typeof newData === 'undefined') {
              if (this.editIndex !== '') {
                  this.project_info.co_investigators[parseInt(this.editIndex)].investigator_name_en = this.detailsFormData.investigator_name_en
                  this.project_info.co_investigators[parseInt(this.editIndex)].investigator_name_bn = this.detailsFormData.investigator_name_bn
              } else {
                  this.project_info.co_investigators.push(JSON.parse(JSON.stringify(this.detailsFormData)))
              }
              this.detailsFormData = {
                investigator_name_en: '',
                investigator_name_bn: ''
              }
              this.editIndex = ''
          } else {
              this.$toast.error({
                  title: '!',
                  message: 'Item already added',
                  color: '#ee5253'
              })
          }
          // reset form
          this.$nextTick(() => {
              this.$refs.form1.reset()
          })
      },
      getThematicAreaList (orgId) {
          if (this.project_info.org_id) {
              const area = this.$store.state.AgriResearch.commonObj.thematicAreaList.filter(item => item.status === 1)
              if (orgId) {
              return area.filter(item => item.org_id === parseInt(orgId))
              }
              return area
          }
      },
      async getCircularList () {
          if (this.project_info.org_id) {
            const params = {
            org_id: parseInt(this.project_info.org_id)
            // office_type_id: parseInt(this.formData.office_type_id),
            // office_id: parseInt(this.formData.office_id)
          }
          await RestApi.getData(agriResearchServiceBaseUrl, circularList, params)
          .then(response => {
            if (response.success) {
              this.circularData = response.data
            }
          })
          const circularListxx = this.circularData.map(item => {
            return Object.assign({ value: item.id, text: this.$i18n.locale === 'bn' ? item.circular_name_bn : item.circular_name, text_en: item.circular_name, text_bn: item.circular_name_bn, memo_no: item.memo_no, submission_deadline: item.submission_deadline, org_id: item.org_id, circular_type: item.circular_type })
          })
          this.circularList = circularListxx.filter(circular => circular.circular_type === 2)
          if (this.id) {
            this.getCircularMemo(this.project_info.circular_id)
          }
        }
      },
      getCircularMemo (orgId) {
          if (this.project_info.circular_id) {
            const memo = this.circularList.find(circular => circular.value === orgId && circular.org_id === parseInt(this.project_info.org_id))
            this.memo_no = memo.memo_no
            this.submission_deadline = memo.submission_deadline
          }
      },
      getProjectData () {
        // const tmpData = this.$store.state.list.find(project => project.id === this.id)
        // return JSON.parse(JSON.stringify(tmpData))
        RestApi.getData(agriResearchServiceBaseUrl, researchProposalDetailsApi, { proposal_id: this.id }).then(response => {
            if (response.success) {
                this.project_info = response.data
                this.project_info.season = JSON.parse(this.project_info.season)
                this.item.circular_name = this.project_info.invitation.circular_name
                this.item.memo_no = this.project_info.invitation.memo_no
                this.getOfficerList()
                this.loadingData = true
            }
            // this.loadingData = false
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
      },
      finalSave () {
        this.$swal({
          title: this.$t('globalTrans.save_msg'),
          showCancelButton: true,
          confirmButtonText: this.$t('globalTrans.yes'),
          cancelButtonText: this.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.project_info.flag = 1
            this.saveUpdate()
          }
        })
      },
      async saveUpdate () {
        const isFormValid = await this.$refs.form.validate()
        if (!isFormValid) {
          return null
        }
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const otd = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.find(item => item.value === this.project_info.office_type_id)
        this.project_info.office_type_name = otd ? otd.text_en : null
        const loadingState = { loading: false, listReload: false }
        result = await RestApi.postData(agriResearchServiceBaseUrl, divisionalProposalSubStoreUpdate, this.project_info)
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)
        if (result.success) {
          this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })
          this.$toast.success({
            title: 'Success',
            message: this.id ? 'Data updated successfully' : 'Data save successfully',
            color: '#D6E09B'
          })
          this.$bvModal.hide('modal-4')
          this.$router.push({ path: '/research-farmer/research-management/proposal-submission' })
        } else {
          this.$refs.form.setErrors(result.errors)
          this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
      },
      async getOfficerList () {
        if (this.projectData.org_id > 0) {
          this.officerLoad = true
            const params = { org_id: parseInt(this.projectData.org_id) }
            const response = await RestApi.getData(authServiceBaseUrl, 'user/list-by-designation', params)
            if (response.success) {
                this.unitLoad = false
                this.officerLoad = false
                this.allOfficerList = response.data
                this.officerList = this.allOfficerList.map(item => ({
                    value: item.user_id,
                    text: this.$i18n.locale === 'bn' ? item.name_bn : item.name,
                    text_en: item.name,
                    text_bn: item.name_bn,
                    designation_id: item.designation_id
                }))
            } else {
                this.unitLoad = false
                this.officerLoad = false
                this.officerList = []
            }
        }
      }
    }
  }
  </script>
