<template>
  <b-container fluid>
    <b-row>
      <b-col xl="12" sm="12">
        <iq-card>
          <template v-slot:body>
                <b-row>
                    <b-col lg="12" sm="12">
                        <!-- <b-row>
                            <b-col xs="12" sm="12" md="12" class="text-right mb-2">
                                <b-button class="text-right" @click="pdfExport()" variant="success">{{  $t('globalTrans.export_pdf') }}</b-button>
                            </b-col>
                        </b-row> -->
                        <b-row>
                            <b-col xl="12" sm="12">
                                <b-row>
                                    <b-col md=12>
                                        <b-table-simple striped bordered small hover>
                                            <tbody>
                                                <b-tr>
                                                    <b-th width="20%">{{ $t('research_manage.invitation_code') }}</b-th>
                                                    <b-td colspan="3">{{ formData.invitation_code }}</b-td>
                                                </b-tr>
                                                 <b-tr>
                                                    <b-th width="20%">{{ $t('research_manage.thematic_area') }}</b-th>
                                                    <b-td colspan="3">{{ (currentLocale == 'bn') ? formData.thematic_name_bn : formData.thematic_name }}</b-td>
                                                </b-tr>
                                            </tbody>
                                        </b-table-simple>
                                        <b-table-simple v-if="formData.proposal_invitation_details" striped bordered small hover>
                                            <thead>
                                                <tr>
                                                    <th class="text-center" width="10%">{{ $t('globalTrans.sl_no') }}</th>
                                                    <th class="text-center" width="30%" scope="col">{{ $t('research_manage.sector_name') }}</th>
                                                    <th class="text-center" width="30%" scope="col">{{ $t('research_manage.sub_sector_name') }}</th>
                                                </tr>
                                            </thead>
                                            <b-tbody>
                                                <b-tr v-for="(item, index) in formData.proposal_invitation_details" :key="index">
                                                    <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                                    <b-td class="text-center">{{ ($i18n.locale=='en') ? $store.state.AgriResearch.commonObj.sectorList.find(doc => doc.value === parseInt(item.sector_id)).text_en : $store.state.AgriResearch.commonObj.sectorList.find(doc => doc.value === parseInt(item.sector_id)).text_bn }}
                                                    </b-td>
                                                    <b-td class="text-center">{{ ($i18n.locale=='en') ? $store.state.AgriResearch.commonObj.subSectorList.find(doc => doc.value === parseInt(item.sub_sector_id)).text_en : $store.state.AgriResearch.commonObj.subSectorList.find(doc => doc.value === parseInt(item.sub_sector_id)).text_bn }}
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                        <b-table-simple striped bordered small hover>
                                          <tbody>
                                                <!-- <b-tr>
                                                    <b-th width="20%">{{ $t('research_manage.research_objective') }}</b-th>
                                                    <b-td colspan="3"> {{ $i18n.locale === 'bn' ? formData.research_objectives_bn : formData.research_objectives }}</b-td>
                                                </b-tr> -->
                                                <b-tr>
                                                    <b-th width="20%">{{ $t('research_manage.circular_description') }}</b-th>
                                                    <b-td colspan="3">
                                                        <slot v-if="$i18n.locale === 'en'">
                                                          <span v-html="formData.circular_description"></span>
                                                        </slot>
                                                        <slot v-if="$i18n.locale === 'bn'">
                                                          <span v-html="formData.circular_description_bn"></span>
                                                        </slot>
                                                  </b-td>
                                                </b-tr>
                                                <b-tr>
                                                   <b-th>{{ $t('research_manage.submission_deadline') }}</b-th>
                                                   <b-td>{{ formData.submission_deadline | dateFormat }}</b-td>
                                                   <b-th class="text-center">{{ $t('globalTrans.attachment') }}</b-th>
                                                   <b-td class="text-center">
                                                     <b v-if="formData.attachment">
                                                      (<a target = '_blank' :href="agriResearchServiceBaseUrl + 'storage/proposal_invitation/original/' + formData.attachment">{{$t('globalTrans.view_download')}}</a> )
                                                     </b>
                                                        <!-- <a v-if="formData.attachment" target="_blank" :href="baseUrl + 'storage/uploads/research-management/original/' + proposal.methodology.attachment" title="Attachment" class="mr-3">{{ proposal.methodology.attachment }}<i class="ri-download-cloud-fill"></i></a> -->
                                                   </b-td>
                                                </b-tr>
                                            </tbody>
                                        </b-table-simple>
                                          <div class="row">
                                            <div class="col-sm-3"></div>
                                            <div class="col text-right">
                                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                                            </div>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
// import { core } from '@/config/pluginInit'
import { agriResearchServiceBaseUrl } from '@/config/api_config'
// import ExportPdf from './export_pdf_details'
export default {
    components: {
    },
  props: ['item'],
  created () {
    if (this.item) {
      const tmp = this.getAppInfo()
      this.formData = tmp
    }
  },
  mounted () {
    // core.index()
    // flatpickr('.fromDate', {})
  },
  data () {
    return {
        loading: false,
      agriResearchServiceBaseUrl: agriResearchServiceBaseUrl,
      formData: {}
    }
  },
  computed: {
      columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('research_manage.sector_name'), class: 'text-left' },
          { label: this.$t('research_manage.sub_sector_name'), class: 'text-left' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'sector_id' },
          { key: 'sub_sector_id' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'sector_id' },
          { key: 'sub_sector_id' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  methods: {
      getAppInfo () {
      return this.item
    },
       getSubSectorList (sectorId) {
            this.subSectorList = this.$store.state.AgriResearch.commonObj.subSectorList.find(item => item.status === 1)
            if (sectorId) {
                return this.subSectorList.find(subsector => subsector.sector_id === sectorId)
            }
            return this.subSectorList
        },
          getBudgetName (myItem) {
            const Obj = this.$store.state.AgriResearch.commonObj.budgetHeadList.find(item => item.value === parseInt(myItem.budget_head_id))
            myItem.code = Obj.budget_head_code
            if (this.$i18n.locale === 'en') {
                 return Obj.text_en
             } else {
                 return Obj.text_bn
             }
        }
    // pdfExport () {
    //   const reportTitle = this.$t('research_manage.proposal_invitation') + ' ' + this.$t('globalTrans.details')
    //   ExportPdf.exportPdfDetails(reportTitle, this.formData, this.formData.proposal_invitation_details, this)
    // }
  }
}
</script>
<style lang="scss">
  .hidden_header {
    display: none
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .contentTitle {
      font-size: 15px; background-color: #005B5B; padding: 1px
  }
</style>
